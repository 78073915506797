import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'

import globalReducer from './modules/globalReducer'
import globalSaga from './modules/globalSaga'

import createStore from './modules/createStore'
import persistedReducer from './modules/persistReducers'

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null

const sagaMiddleware = createSagaMiddleware({ sagaMonitor })
const middlewares = [sagaMiddleware]

const store = createStore(persistedReducer(globalReducer), middlewares)
const persistor = persistStore(store)

sagaMiddleware.run(globalSaga)

export { store, persistor }
