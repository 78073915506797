const INITIAL_STATE = {}

const geolocation = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@geolocation/SET_COORDINATES': {
      return {
        ...state,
        latitude: action.latitude,
        longitude: action.longitude,
        hasCoordinates: true,
      }
    }
    default:
      return state
  }
}

export default geolocation
