const INITIAL_STATE = {
  list: [],
  current: {},
}

const campaign = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@campaign/SET_LIST': {
      return {
        ...state,
        list: action.list,
      }
    }
    case '@campaign/SET_CURRENT': {
      return {
        ...state,
        current: action.campaign,
      }
    }
    default:
      return state
  }
}

export default campaign
