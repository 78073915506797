import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from '../../../services/api'

import {
  userAccount,
  addCreditCard,
  getCreditCards,
  updateUserDetails,
} from './actions'
import { setAccount } from '../../../services/profile'

function* loginRequest() {
  const auth = yield select(state => state.auth)

  if (!auth.token) return

  api.defaults.headers.Authorization = `Bearer ${auth.token}`

  try {
    const response = yield call(api.get, `/account`)

    if (response.status !== 200) {
      toast.error(response.message)
    }

    const { avatar, name, email, cpf, universalId, categories } = response.data

    yield put(
      userAccount({ avatar, name, email, cpf, universalId, categories })
    )
  } catch (error) {
    if (error.response && error.response.data) {
      toast.warn(error.response.data.Message)
    }
    if (error.message) {
      toast.warn(error.message)
    }
  }

  try {
    const creditCardResponse = yield call(api.get, `/account/credit-card`)

    if (creditCardResponse.status === 200) {
      yield* creditCardResponse.data.map(creditCard =>
        put(addCreditCard(creditCard))
      )
    }
  } catch (error) {}
}

function* updateUserAccountDetails({ payload: { name, email, cpf } }) {
  try {
    const response = yield setAccount({
      name,
      email,
      cpf,
    })

    if (response.status !== 204) {
      setLoading(false)
      toast.error(response.data.message)
    }
  } catch (error) {}
}

function* getCreditCardRequest() {
  try {
    const response = yield call(api.get, `/account/credit-card`)
    const { data: listOfCards } = response
    yield put(getCreditCards(listOfCards))
  } catch (error) {
    yield put(getCreditCards([]))
  }
}

export default all([
  takeLatest('@user/GET_ACCOUNT_INFO', loginRequest),
  takeLatest('@user/UPDATE_USER_DETAILS', updateUserAccountDetails),
  takeLatest('@user/UPDATE_CREDIT_CARDS_REQUEST', getCreditCardRequest),
])
