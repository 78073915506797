export function userAccountRequest() {
  return {
    type: '@user/GET_ACCOUNT_INFO',
  }
}

export function userAccount({
  avatar,
  name,
  email,
  cpf,
  universalId,
  categories,
}) {
  return {
    type: '@user/SET_ACCOUNT',
    payload: { avatar, name, email, cpf, universalId, categories },
  }
}

export function updateUserDetails({ name, email, cpf }) {
  return {
    type: '@user/UPDATE_USER_DETAILS',
    payload: { name, email, cpf },
  }
}

export function getCreditCards(listOfCreditCard) {
  return { type: '@user/UPDATE_CREDIT_CARDS', payload: { listOfCreditCard } }
}

export function getCreditCardsRequest() {
  return { type: '@user/UPDATE_CREDIT_CARDS_REQUEST' }
}

export function addCreditCard({
  brandName,
  cardMask,
  expirationDate,
  holderName,
  id,
}) {
  return {
    type: '@user/SET_NEW_CREDIT_CARD',
    payload: {
      brandName,
      cardMask,
      expirationDate,
      holderName,
      id,
    },
  }
}

export function removeCreditCard(id) {
  return {
    type: '@user/REMOVE_CREDIT_CARD',
    payload: {
      creditCardId: id,
    },
  }
}
