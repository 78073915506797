import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import api from '../../../services/api'

import {
  setInstitutionsList,
  setCurrentInstitute,
  setCurrentInstituteCampaigns,
} from './actions'

function* setInstitutionsRequest() {
  const geolocation = yield select(state => state.geolocation)
  const response = yield call(api.get, `/institutions/`, {
    params: {
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
    },
  })
  yield put(setInstitutionsList(response.data.results, response.data.pages))
}

function* setCurrentInstitutionRequest({ id }) {
  const response = yield call(api.get, `/institutions/${id}`)

  try {
    const campaignsResponse = yield call(
      api.get,
      `/institutions/${id}/campaigns`
    )
    yield put(setCurrentInstituteCampaigns(campaignsResponse.data))
  } catch (err) {
    toast.error(err)
  }

  yield put(setCurrentInstitute(response.data))
}

export default all([
  takeLatest('@institution/SET_LIST_REQUEST', setInstitutionsRequest),
  takeLatest('@institution/SET_CURRENT_REQUEST', setCurrentInstitutionRequest),
])
