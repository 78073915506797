export function setCampaignsListRequest(options) {
  return {
    type: '@campaign/SET_LIST_REQUEST',
    options,
  }
}

export function setCampaignsList(list) {
  return {
    type: '@campaign/SET_LIST',
    list,
  }
}

export function setCurrentCampaignRequest(id) {
  return {
    type: '@campaign/SET_CURRENT_REQUEST',
    id,
  }
}

export function setCurrentCampaign(campaign) {
  return {
    type: '@campaign/SET_CURRENT',
    campaign,
  }
}
