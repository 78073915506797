import { STEP } from '../../../utils/enum/login'

const INITIAL_STATE = {
  emailMask: null,
  facebookCode: null,
  googleCode: null,
  logged: false,
  loading: false,
  phoneNumber: null,
  phoneRecognized: false,
  step: STEP.phoneAuthentication,
  callbackRoute: '/',
}

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@auth/LOGIN_REQUEST': {
      return {
        ...state,
        loading: true,
      }
    }
    case '@auth/LOGIN_SUCCESS': {
      return {
        ...state,
        loading: false,
        logged: true,
        phonenumber: action.payload.phonenumber,
        token: action.payload.token,
      }
    }
    case '@auth/LOGOUT': {
      return INITIAL_STATE
    }
    case '@auth/LOGIN_FAIL': {
      return {
        ...state,
        loading: false,
      }
    }
    case '@auth/RESET_LOGIN_PROCESS': {
      return {
        ...state,
        emailMask: null,
        facebookCode: null,
        googleCode: null,
        phoneRecognized: false,
        loading: false,
        step: STEP.phoneAuthentication,
      }
    }
    case '@auth/PHONE_RECOGNIZED': {
      return {
        ...state,
        phoneRecognized: true,
        step: STEP.emailConfirmation,
      }
    }
    case '@auth/PHONE_NOT_RECOGNIZED': {
      return {
        ...state,
        phoneRecognized: false,
        step: STEP.registration,
      }
    }
    case '@auth/SET_EMAIL_MASK': {
      return {
        ...state,
        emailMask: action.payload.emailMask,
      }
    }
    case '@auth/SET_FACEBOOK_CODE': {
      return {
        ...state,
        facebookCode: action.payload.facebookCode,
      }
    }
    case '@auth/SET_PHONE_NUMBER': {
      return {
        ...state,
        phoneNumber: action.payload.phoneNumber,
      }
    }
    case '@auth/SET_CALL_BACK_ROUTE': {
      return {
        ...state,
        callbackRoute: action.payload.route,
      }
    }
    case '@auth/SET_LOADING': {
      return {
        ...state,
        loading: true,
      }
    }
    case '@auth/UNSET_LOADING': {
      return { ...state, loading: false }
    }
    default:
      return state
  }
}

export default auth
