import { combineReducers } from 'redux'

import auth from './auth'
import campaign from './campaign'
import donation from './donation'
import geolocation from './geolocation'
import institution from './institution'
import user from './user'

export default combineReducers({
  auth,
  campaign,
  donation,
  geolocation,
  institution,
  user,
})
