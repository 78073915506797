import { all, call, put, select, takeLatest } from 'redux-saga/effects'

import api from '../../../services/api'

import { setCampaignsList, setCurrentCampaign } from './actions'

function* setCampaignsRequest() {
  const geolocation = yield select(state => state.geolocation)
  const response = yield call(api.get, `/campaigns/`, {
    params: {
      latitude: geolocation.latitude,
      longitude: geolocation.longitude,
      show: false,
    },
  })
  yield put(setCampaignsList(response.data.results))
}

function* setCurrentCampaignRequest({ id }) {
  const response = yield call(api.get, `/campaigns/${id}`)
  yield put(setCurrentCampaign(response.data))
}

export default all([
  takeLatest('@campaign/SET_LIST_REQUEST', setCampaignsRequest),
  takeLatest('@campaign/SET_CURRENT_REQUEST', setCurrentCampaignRequest),
])
