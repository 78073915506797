export function createAccountRequest({ email, cpf, username }) {
  const payload = {
    email,
    username,
  }

  if (cpf) {
    payload.cpf = cpf
  }

  return {
    type: '@auth/CREATE_ACCOUNT_REQUEST',
    payload,
  }
}

export function loginRequest(email) {
  return {
    type: '@auth/LOGIN_REQUEST',
    payload: {
      email,
    },
  }
}

export function loginSuccess({ id, name, token, phonenumber }) {
  return {
    type: '@auth/LOGIN_SUCCESS',
    payload: { id, name, token, phonenumber },
  }
}

export function logoutRequest() {
  return {
    type: '@auth/LOGOUT_REQUEST',
  }
}

export function resetLogin() {
  return {
    type: '@auth/RESET_LOGIN_PROCESS',
  }
}

export function logout() {
  return {
    type: '@auth/LOGOUT',
  }
}

export function loginFail() {
  return {
    type: '@auth/LOGIN_FAIL',
  }
}

export function setPhoneRecognized() {
  return {
    type: '@auth/PHONE_RECOGNIZED',
  }
}

export function setPhoneNotRecognized() {
  return {
    type: '@auth/PHONE_NOT_RECOGNIZED',
  }
}

export function setEmailMask(emailMask) {
  return {
    type: '@auth/SET_EMAIL_MASK',
    payload: {
      emailMask,
    },
  }
}

export function setFacebookCode(facebookCode) {
  return {
    type: '@auth/SET_FACEBOOK_CODE',
    payload: {
      facebookCode,
    },
  }
}

export function setPhoneNumber(phoneNumber) {
  return {
    type: '@auth/SET_PHONE_NUMBER',
    payload: {
      phoneNumber,
    },
  }
}

export function setCallbackRoute(route) {
  return {
    type: '@auth/SET_CALL_BACK_ROUTE',
    payload: { route },
  }
}

export function setLoading() {
  return {
    type: '@auth/SET_LOADING',
  }
}

export function unsetLoading() {
  return {
    type: '@auth/UNSET_LOADING',
  }
}
