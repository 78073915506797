import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby-plugin-intl'

import api from '../../../services/api'

import {
  loginSuccess,
  loginFail,
  logout,
  unsetLoading,
  setLoading,
} from './actions'
import { userAccountRequest } from '../user/actions'

function* loginRequest({ payload: { email } }) {
  const googleCode = yield select(state => state.auth.googleCode)
  const phoneNumber = yield select(state => state.auth.phoneNumber)
  const callbackRoute = yield select(state => state.auth.callbackRoute)

  yield put(setLoading())

  try {
    const response = yield call(api.post, `/account/login`, {
      email,
      phoneNumber,
      facebookCode: 'm@d3!Nw3B.D3v.T8k3N',
      googleCode,
    })

    if (response.status !== 200) {
      return toast.error(response.message)
    }

    const { id, name, phonenumber, token } = response.data

    yield put(loginSuccess({ id, name, token, phonenumber }))
    yield put(userAccountRequest())
    yield put(unsetLoading())

    return navigate(callbackRoute || '/')
  } catch (error) {
    if (error.response && error.response.data) {
      toast.warn(error.response.data.Message)
    } else if (error.message) {
      toast.warn(error.message)
    }

    return yield put(loginFail())
  }
}

function* createAccount({ payload: { email, cpf, username } }) {
  const phoneNumber = yield select(state => state.auth.phoneNumber)
  const callbackRoute = yield select(state => state.auth.callbackRoute)

  yield put(setLoading())

  try {
    const response = yield call(api.post, `/account/create`, {
      email,
      cpf,
      name: username,
      facebookCode: 'm@d3!Nw3B.D3v.T8k3N',
      phone: phoneNumber,
    })

    if (response.status !== 200) {
      return toast.error(response.message)
    }

    const { id, name, phonenumber, token } = response.data

    yield put(loginSuccess({ id, name, token, phonenumber }))
    yield put(userAccountRequest())
    yield put(unsetLoading())

    return navigate(callbackRoute || '/')
  } catch (error) {
    if (error.response && error.response.data) {
      toast.warn(error.response.data.Message)
    } else if (error.message) {
      toast.warn(error.message)
    }
    return yield put(loginFail())
  }
}

function* logoutRequest() {
  try {
    const response = yield call(api.post, `/account/logoff`)

    if (response.errorCode) {
      return toast.error(response.Message)
    }

    navigate('/login')

    return yield put(logout())
  } catch (error) {
    if (error.response && error.response.data) {
      return toast.warn(error.response.data.Message)
    }
    if (error.message) {
      return toast.warn(error.message)
    }
    return null
  }
}

export function* setToken() {
  const auth = yield select(state => state.auth)

  if (!auth.token) return

  api.defaults.headers.Authorization = `Bearer ${auth.token}`
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/LOGIN_REQUEST', loginRequest),
  takeLatest('@auth/LOGOUT_REQUEST', logoutRequest),
  takeLatest('@auth/CREATE_ACCOUNT_REQUEST', createAccount),
])
