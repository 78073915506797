import { all } from 'redux-saga/effects'

import auth from './auth/sagas'
import campaign from './campaign/sagas'
import institution from './institution/sagas'
import users from './user/sagas'

export default function* globalSaga() {
  return yield all([auth, campaign, institution, users])
}
