/* eslint-disable camelcase */
import api from './api'

export async function getAccount() {
  return api.get('/account')
}

export async function setAccount({ avatar, name, email, cpf, categories }) {
  return api.put('/account', {
    avatar,
    name,
    email,
    cpf,
    categories: [],
  })
}

export async function setPhone({
  cpf,
  email,
  oldPhoneNumber,
  newPhoneNumber,
  userId,
}) {
  return api.put('/account/updatePhone', {
    cpf,
    email,
    oldPhoneNumber,
    newPhoneNumber,
    userId,
  })
}

export async function getAddress() {
  return api.get('/account/address')
}

export async function setAddress({
  street,
  number,
  additionalData,
  neighborhood,
  city,
  state,
  postalCode,
  cpf,
}) {
  return api.put('/account/address', {
    street,
    number,
    additionalData,
    neighborhood,
    city,
    state,
    postalCode,
    cpf,
  })
}

export async function getProfile({ email, cpf }) {
  return api.get('/account/profile', { email, cpf })
}

export async function getCreditCard() {
  return api.get('/account/credit-card')
}

export async function setCreditCard({
  holder_name,
  expiration_month,
  expiration_year,
  security_code,
  card_number,
}) {
  return api.post('/account/credit-card', {
    holder_name,
    expiration_month,
    expiration_year,
    security_code,
    card_number,
  })
}

export async function removeCreditCard(id) {
  return api.delete(`/account/credit-card/${id}`)
}
