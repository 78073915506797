const INITIAL_STATE = {
  profile: {},
  creditCards: [],
}

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@user/GET_ACCOUNT_REQUEST': {
      return state
    }
    case '@user/SET_ACCOUNT': {
      return {
        ...state,
        profile: {
          avatar: action.payload.avatar,
          name: action.payload.name,
          email: action.payload.email,
          cpf: action.payload.cpf,
          universalId: action.payload.universalId,
          categories: action.payload.categories,
        },
      }
    }
    case '@user/UPDATE_USER_DETAILS': {
      return {
        ...state,
        profile: {
          avatar: action.payload.avatar,
          name: action.payload.name,
          email: action.payload.email,
          cpf: action.payload.cpf,
          universalId: action.payload.universalId,
          categories: action.payload.categories,
        },
      }
    }
    case '@user/UPDATE_CREDIT_CARDS': {
      return {
        ...state,
        creditCards: action.payload.listOfCreditCard,
      }
    }
    case '@user/SET_NEW_CREDIT_CARD': {
      return {
        ...state,
        creditCards: [...state.creditCards, action.payload],
      }
    }
    case '@user/REMOVE_CREDIT_CARD': {
      return {
        ...state,
        creditCards: state.creditCards.filter(
          creditCard => creditCard.id !== action.payload.creditCardId
        ),
      }
    }
    case '@auth/LOGIN_SUCCESS': {
      return {
        ...state,
        profile: {
          id: action.payload.id,
          name: action.payload.name,
          phonenumber: action.payload.phonenumber,
          token: action.payload.token,
        },
      }
    }
    case '@auth/LOGOUT': {
      return INITIAL_STATE
    }
    default:
      return state
  }
}

export default auth
