export function setInstitutionsListRequest(options) {
  return {
    type: '@institution/SET_LIST_REQUEST',
    options,
  }
}

export function setInstitutionsList(list, totalPages) {
  return {
    type: '@institution/SET_LIST',
    payload: {
      list,
      totalPages,
    },
  }
}

export function setCurrentInstituteRequest(id) {
  return {
    type: '@institution/SET_CURRENT_REQUEST',
    id,
  }
}

export function setCurrentInstitute(institute) {
  return {
    type: '@institution/SET_CURRENT',
    institute,
  }
}

export function setCurrentInstituteCampaigns(campaigns) {
  return {
    type: '@institution/SET_CURRENT_CAMPAIGNS',
    campaigns,
  }
}
