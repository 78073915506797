const INITIAL_STATE = {
  amount: 0,
  currency: null,
  minimumValue: 10,
  isMaintenance: false,
  recipient: {
    id: null,
    type: null,
  },
}

const auth = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@donation/SET_AMOUNT': {
      return { ...state, amount: action.amount }
    }
    case '@donation/RESET_AMOUNT': {
      return { ...state, amount: 0 }
    }
    case '@donation/SET_CURRENCY': {
      return { ...state, currency: action.currency }
    }
    case '@donation/SET_MINIMUM_VALUE': {
      return { ...state, minimumValue: action.value }
    }
    case '@donation/SET_RECIPIENT': {
      return { ...state, recipient: action.payload }
    }
    case '@donation/SET_MAINTENACE': {
      return { ...state, isMaintenance: action.isMaintenance }
    }
    case '@auth/LOGOUT': {
      return INITIAL_STATE
    }
    default:
      return state
  }
}

export default auth
