const INITIAL_STATE = {
  current: {},
  list: [],
  totalPages: 0,
}

const institution = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case '@institution/SET_LIST': {
      return {
        ...state,
        list: action.payload.list,
        totalPages: action.payload.totalPages,
      }
    }
    case '@institution/SET_CURRENT': {
      return {
        ...state,
        current: action.institute,
      }
    }
    case '@institution/SET_CURRENT_CAMPAIGNS': {
      return {
        ...state,
        current: { campaigns: action.campaigns },
      }
    }
    default:
      return state
  }
}

export default institution
